import * as routesUtils from '../utils/RoutesUtils'
import { API } from '../core/app.routes'
import httpClient from '../core/httpClient'

const estimateService = () => {
	return {
		many: params => {
			const path = routesUtils.getApiPath(API.ESTIMATES, params)
			return httpClient.get(path)
		},
		get: estimateId => {
			const path = routesUtils.getApiPath(API.ESTIMATES_DETAIL, { estimateId })
			return httpClient.get(path)
		},
		editVehicle: ({ estimateId, vehicleId, status, company_id }) => {
			const path = routesUtils.getApiPath(API.VEHICLE_DETAIL, { estimateId, vehicleId });
			return httpClient.put(path, { status, company_id });
		},
		edit: ({ estimate, user }) => {
			const path = routesUtils.getApiPath(API.ESTIMATES_DETAIL, { estimateId: estimate._id });
			return httpClient.put(path, {
				company_id: estimate.company._id,
				user,
				signature: estimate.signature,
				disclosures: estimate.disclosures,
			});
		},
		paymentHistory: ({estimate, ...params}) => {
			const path = routesUtils.getApiPath(API.ESTIMATES_PAYMENT, { estimateId: estimate._id });
			return httpClient.get(path, { params });
		}
	}
}

export default estimateService
