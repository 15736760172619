import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { isEmpty } from 'lodash'
import axios from 'axios'
import { ContainerProvider } from '../../containerContext'
import { useApi, useLoading } from '../../../hooks'
import EstimateDetail from './EstimateDetail'
import { Container } from 'react-bootstrap'
import useAppContext from '../../app'
import Riband from '../../Riband'
import Signature from './Signature'
import toast from '../../toast'
import { VEHICLE_STATUS } from '../../../core/constants1'
import { getVehicleInfo } from '../../../utils/estimates'
import { dataURLToBlob } from '../../../utils/UploadImage'

const Index = () => {
	const queryClient = useQueryClient()
  	const [state, setState] = useState({
		isShowSignature: false,
		isShowPopupVerify: false,
		isShowModalReject: false,
		isShowModalDisclosure: false,
		isShowModalDeposit: false,
		isShowModalProcessingCard: false,
	})
	const { id } = useParams()
	const { estimateService, mainService } = useApi()
	const { store: { isLog } } = useAppContext()
	const [payments, setPayments] = useState([])

	const closeModalPhoto = () => {
		setState(prev => {
			return { ...prev, vehicle: null, showModalPhoto: false, initialSlide: 0 }
		})
	}

	const closeModalVerify = () => {
		setState(prev => ({ ...prev, isShowPopupVerify: false }))
	}

	const closeModalReject = () => {
		setState(prev => ({ ...prev, isShowModalReject: false }))
	}

	const closeModalDisclosure = () => {
		setState(prev => ({ ...prev, isShowModalDisclosure: false }))
	}

	const closeModalDeposit = () => {
		setState(prev => ({ ...prev, isShowModalDeposit: false }))
	}

	const closeModalProcessingCard = () => {
		setState(prev => ({ ...prev, isShowModalProcessingCard: false }))
	}

	const showSignature = () => {
		setState(prev => ({ ...prev, isShowSignature: true }));
	}

	const verifyConditionToApprove = (vehicle, companyRules) => {
		const { stock, vin, checkIn: { vehicle: { severity }, insurance: { dateOfLoss } } } = vehicle;
		const vehicleCondition = {
			stock: !isEmpty(stock),
			vin: !isEmpty(vin),
			severity: !isEmpty(severity),
			dateOfLoss: !isEmpty(dateOfLoss),
		};
		const rules = {
			dateOfLossRule: companyRules?.dateOfLoss?.active,
			severityRule: companyRules?.severity?.active,
			stockRule: companyRules?.stock?.active,
			vinRule: companyRules?.vin?.active,
		};

		if (rules.dateOfLossRule && !vehicleCondition.dateOfLoss) {
			return false;
		}
		if (rules.severityRule && !vehicleCondition.severity) {
			return false;
		}
		if (rules.stockRule && !vehicleCondition.stock) {
			return false;
		}
		if (rules.vinRule && !vehicleCondition.vin) {
			return false;
		}
		return true;
	}

	const hidePayment = () => {
		setState(Prev => ({ ...Prev, isPayment: false, showAmount: false }))
	}

	const toPayment = () => {
		const { estimate: { company: stripe } } = data;
		if (shouldShowModalProcessingCard(isLog, stripe)) {
			setState(prev => ({ ...prev, isShowModalProcessingCard: true }));
  			return;
		}

		setState(prev => ({ ...prev, isPayment: true}))
	}

	const toReceipt = (paymentData) => {
		setState(prev => ({ ...prev, isReceipt: true, isPayment: false, paymentData }))
	}

	const toApproveEst = () => {
		const { estimate: { payment, depositFlat, disclosures, vehicles, company: { rules: { estimates: companyRules } }, client: { _id: { rules: { estimates: estimateRules, active } } } } } = data;
		const verifyClient = !active || vehicles.every(vehicle => verifyConditionToApprove(vehicle, estimateRules));
		const verify = !active ? vehicles.every(vehicle => verifyConditionToApprove(vehicle, companyRules)) : true;
		const isAtLeastVehicleApprove = vehicles.some(vehicle => vehicle.status === VEHICLE_STATUS.APPROVED);
		const isAllVehicleHaveStatus = vehicles.every(vehicle => vehicle.status !== VEHICLE_STATUS.UNDECIDED);
		const isEnableApproveEst = isAtLeastVehicleApprove && isAllVehicleHaveStatus;
		
		if (shouldShowModalDeposit(isLog, payment, depositFlat)) {
			setState(prev => ({ ...prev, isShowModalDeposit: true }));
  			return;
		}

		if (!isEnableApproveEst) {
			setState(prev => ({ ...prev, isShowModalReject: true }));
			return;
		}

		if (verify && verifyClient) {
			disclosures.length > 0 ? setState(prev => ({ ...prev, isShowModalDisclosure: true })) : setState(prev => ({ ...prev, isShowSignature: true }));
		} else {
			setState(prev => ({ ...prev, isShowPopupVerify: true }))
		}
	}

	const hideReceipt = () => {
		setState(prev => ({ ...prev, isReceipt: false, showAmount: false, paymentData: null}))
	}

	const showPhotoModal = (vehicle = {}, initialSlide = 0) => {
		setState(prev => {
			return { ...prev, vehicle, showModalPhoto: true, initialSlide }
		})
	}

	const { isLoading, isSuccess, data = []} = useQuery('estimatesDetail',() => {
		return estimateService.get(id)
	}, {
		refetchOnWindowFocus: false
	})

	const approveEst = useMutation(async (estimate) => {
		const companyId = estimate.company._id;
		const file = dataURLToBlob(estimate.signature);
		const { signed_request, url } = await mainService.uploadFile(companyId);

		const headers = { 'x-amz-acl': 'public-read', 'Content-Type': 'image/png' };
		await axios.put(signed_request, file, { headers });

		await estimateService.edit({
			estimate: {
				...estimate,
				signature: url.split('/').pop(),
			},
			user: estimate.createdBy,
		});

		return {
			...estimate,
			signature: url,
		};
	}, {
		onSuccess: dataResponse => {
			const dataUpdated = {
				...data,
				estimate: dataResponse,
			};
			queryClient.setQueryData(['estimatesDetail'], dataUpdated);
			setState(prev => ({ ...prev, isShowSignature: false }))
			toast.success({
				title: 'Approval Successful',
				body: `Your approval has been successful. We will notify ${data.estimate.company.name} that you have approved this estimate.`
			})
		},
		onError: () => {
			toast.error({ title: 'Something went wrong' });
		}
	});

	const approveOrRejectVehicle = useMutation(async (body) => {
		const { estimateId, vehicleId, status } = body;

		const payload = {
			estimateId,
			vehicleId,
			status,
			company_id: data.estimate.company._id
		}
		await estimateService.editVehicle(payload);
		return payload;
	}, {
		onSuccess: (dataResponse) => {
			let vehicleUpdate = {};
			const dataUpdated = { 
				...data,
				estimate: {
					...data.estimate,
					vehicles: data.estimate.vehicles.map(vh => {
						if (vh._id === dataResponse.vehicleId) {
							vh.status = dataResponse.status;
							vehicleUpdate = vh;
						}
						return vh;
					})
				}
			};

			queryClient.setQueryData(['estimatesDetail'], dataUpdated);

			if (dataResponse.status === VEHICLE_STATUS.DECLINED) {
				toast.error({ 
					title: 'Vehicle Rejected',
					body: `The ${getVehicleInfo(vehicleUpdate)} has been removed from this estimate. The Estimate total has been updated.`
				});
				return;
			}
			if (dataResponse.status === VEHICLE_STATUS.APPROVED) {
				toast.success({ 
					title: 'Vehicle Approved',
					body: `The ${getVehicleInfo(vehicleUpdate)} has been accepted from this estimate. The Estimate total has been updated.`
				});
				return;
			}
		},
		onError: () => {
			toast.error({ title: 'Something went wrong' });
		}
	});

	useEffect(() => {
		return () => {
			queryClient.removeQueries(['estimatesDetail'])
		}
	}, [queryClient]);

	useEffect(() => {
		if (data.estimate) {
			const params = {
				filter: {
					"estimate": data.estimate._id,
				},
				companyId: data.estimate.company._id,
				estimate: data.estimate
			}
			estimateService.paymentHistory(params).then(data => setPayments(data.payments))
		}
	}, [data.estimate]);

	useLoading(isLoading);
	useLoading(approveOrRejectVehicle.isLoading);
	useLoading(approveEst.isLoading);

	return (
		<ContainerProvider
			state={state}
			setState={setState}
			data={data.estimate}
			hidePayment={hidePayment}
			closeModalPhoto={closeModalPhoto}
			showPhotoModal={showPhotoModal}
			toPayment={toPayment}
			toReceipt={toReceipt}
			hideReceipt={hideReceipt}
			toApproveEst={toApproveEst}
			approveOrRejectVehicle={approveOrRejectVehicle}
			approveEst={approveEst}
			payments={payments}
			closeModalVerify={closeModalVerify}
			closeModalReject={closeModalReject}
			closeModalDisclosure={closeModalDisclosure}
			closeModalDeposit={closeModalDeposit}
			closeModalProcessingCard={closeModalProcessingCard}
			showSignature={showSignature}
		>
			<div className='w-100 d-flex flex-column'>
			 {!isLog && data?.estimate?.client?._id?.canAccessPortal && (
					<Riband />
				)}
				<Container className='mx-auto'>
					{
						isSuccess && (
							<>
								{
									state.isShowSignature ? <Signature /> : <EstimateDetail />
								}
							</>
						)
					}
				</Container>
			</div>
		</ContainerProvider>
	)
}

export default Index;

const shouldShowModalDeposit = (isLogged, payment, depositFlat) => {
	const token = localStorage['access_token'];
	const payload = token ? JSON.parse(window.atob(token.split('.')[1])) : null;
	const isPaid = payment.paid === 'paid';
  
	if (isLogged) {
		return depositFlat !== 0 && !isPaid;
	}

	return payload?.deposit && !isPaid;
};

const shouldShowModalProcessingCard = (isLogged, stripe) => {
	if (!isLogged) {
		return false;
	}
	  
	if (stripe && stripe.userID) {
		return false;
	}

	return true;
}